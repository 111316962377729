@use "@nutt/ui/theme";
@use "@nutt/ui/fonts";
@use "@nutt/ui/base";
@use "sass:color";

// Colors
$colors: (
  primary: #02a7ff,
  primary-light: color.adjust(#02a7ff, $lightness: 52%),
  base: color.adjust(#1b2338, $lightness: 10%),
  base-dark: #1b2338,
  base-light: color.adjust(#1b2338, $lightness: 72%),
  error: #e5373c,
  error-light: color.adjust(#e5373c, $lightness: 40%),
  info: #0092d5,
  info-light: color.adjust(#0092d5, $lightness: 55%),
  success: #27ae60,
  success-light: color.adjust(#27ae60, $lightness: 50%),
  warning: #f39c11,
  warning-light: color.adjust(#f39c11, $lightness: 44%),
  special: #8e43ad,
  special-light: color.adjust(#8e43ad, $lightness: 46%),
);
@include theme.colors($colors);

// Fonts
$fonts: (
  title: "Gilroy",
  text: "Gilroy",
);
@include theme.fonts($fonts);

// Icons
@include theme.icons();

// Gilroy Client Font
@font-face {
  font-family: "Gilroy";
  font-weight: 200;
  src: url("/assets/fonts/gilroy/Gilroy-Light.otf");
}

@font-face {
  font-family: "Gilroy";
  font-weight: 400;
  src: url("/assets/fonts/gilroy/Gilroy-Regular.otf");
}

@font-face {
  font-family: "Gilroy";
  font-weight: 500;
  src: url("/assets/fonts/gilroy/Gilroy-Medium.otf");
}

@font-face {
  font-family: "Gilroy";
  font-weight: 600;
  src: url("/assets/fonts/gilroy/Gilroy-Bold.otf");
}

@font-face {
  font-family: "Gilroy";
  font-weight: 700;
  src: url("/assets/fonts/gilroy/Gilroy-Extrabold.otf");
}
